// Module
var code = `<template>
  <require from="./bitcoin_account_success.scss"></require>
  <require from="../../../../components/animated_checkmark/animated_checkmark"></require>
  <require from="../../../../components/card-banka-detailled/card-banka-detailled"></require>
  <section id="bitcoin_account_success" class="flex-column">
    <div class="flex-grow"></div>
    <animated-checkmark></animated-checkmark>
    <div class="flex-grow"></div>
  </section>
</template>
`;
// Exports
export default code;