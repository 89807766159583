import { YapilyBankDetailsModel } from "../../../components/models/YapilyModels/YapilyBankDetailsModel";
import { YapilyAccountModel } from "../../../components/models/YapilyModels/YapilyAccountModel";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { YapilyTransactionModel } from "components/models/YapilyModels/YapilyTransactionModel";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { SingletonService } from "singleton";
import moment from "moment";
import { I18N } from "aurelia-i18n";
import { CategoryScale } from "chart.js";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { DialogController } from "aurelia-dialog";
import { BitcoinTransactionModel } from "components/models/BitcoinTransactionModel";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";

@autoinject
export class TransactionsDetailsBitcoin {
  private accountName: string;
  private accountID;
  private me;

  private amount: number;
  private currency: string;

  private comment: string;

  private date: string;
  private tx_bsv: BitcoinTransactionModel;
  private wallet;
  private receiver;

  constructor(
    private singleton: SingletonService,
    private i18n: I18N,
    private router: Router,
    private paymailWalletHttpClient: PaymailWalletClient,
    private dialogController: DialogController
  ) {
    this.tx_bsv = this.singleton.getSelectedTransactionBitcoin();
    this.me = this.singleton.getMe();
  }

  activate() {
    console.log(this.tx_bsv);
  }

  async bind() {
    this.receiver = await this.getPublicProfileFromPaymail(
      this.tx_bsv.senderPaymail
    );
    this.wallet = this.singleton.getCryptoWalletSelected();
  }

  async setLangAndDate(date: Date) {
    moment.locale(await this.i18n.getLocale());
    this.date = moment(date).format("LLLL");
  }

  goToHome() {
    this.router.navigateToRoute("home");
  }

  async getPublicProfileFromPaymail(paymail: string) {
    let request = await this.paymailWalletHttpClient.fetch(
      "/bsvalias/public-profile/" + paymail
    );
    let is200OK = await checkResponseStatus(request);
    this.receiver = await is200OK.json();
    console.log(this.receiver);
    return this.receiver;
  }
}
