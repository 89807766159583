import { autoinject } from "aurelia-dependency-injection";
import { Router } from "aurelia-router";
import { P2PWallet } from "bsv-wallet";
import { bsv } from "bsv";
import { User } from "components/models/UserModel";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { NewsletterHttpClients } from "http_clients/NewsletterHttpClients";
import { WalletInfo, WalletsManager } from "services/WalletManager.service";
import { SingletonService } from "singleton";
import { I18N } from "aurelia-i18n";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";
import { connectTo } from "aurelia-store";
import { State } from "state";
import { EventAggregator } from "aurelia-event-aggregator";
import { WalletModel } from "services/models/WalletModel";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { WalletService } from "services/wallets.service";
import { default as env } from "../../../../../config/environment.json";
import * as QRCode from "qrcode";
import { getPublicProfile } from "services/paymailClient";
import init, {
  ExtendedPrivateKey,
  ExtendedPublicKey,
} from "bsv-wasm/bsv_wasm";
@connectTo()
@autoinject()
export class BitcoinAccountCreateKey {
  private privateKey: string;
  private words: string[] = [];
  private isLoading: boolean = true;
  private name: string;
  private isCopied: boolean = false;
  private isPrinted: boolean = false;
  private copyEnabled: boolean = true;
  private sendMailEnabled: boolean = true;
  private myNewWalletName;
  private newWalletPaymail;
  private send_email: boolean = false;
  private me: User;
  private actionTaken = 0;
  private emailHasBeenSent = false;
  private isTimerDone = false;
  private allCryptoWallets: Array<WalletInfo>;
  private displayError = false;
  private errorMessage: string;
  private state: State;

  //qrcode
  private url: string = "";
  private qrcode_canvas;

  constructor(
    private router: Router,
    private bitcoinEmail: NewsletterHttpClients,
    private paymailWalletHttpClient: PaymailWalletClient,
    private i18n: I18N,
    private walletService: WalletService,
    private ea: EventAggregator
  ) {}

  checkHowManyWallet() {
    return this.state.wallets.filter((wallet) => wallet.isBlockchain);
  }

  async activate(params, { newWalletName, newWalletPaymail }) {
    this.myNewWalletName = params.newWalletName;
    this.newWalletPaymail = params.newWalletPaymail;
  }

  async bind() {
    let blockChainWallets = this.checkHowManyWallet();
    if (blockChainWallets.length > 0 && this.state.me.isSubscribed === false) {
      alert("Please subscribe to MyBanka to create multiple wallets");
      this.displayError = true;
      this.router.navigateToRoute("home");
      return;
    }

    //Changing title to wallet name for pdf printing file named like the wallet / useful to find it later
    this.router.currentInstruction.config.navModel.setTitle(this.myNewWalletName);

    let paymailAvailable = await this.checkIfPaymailAvailable();

    if (paymailAvailable) {
      setTimeout(async () => {
        const wallet = new P2PWallet({ key: "", network: "livenet" } as any);
        this.words = wallet.getPrivateKey().split(" ");
        this.isLoading = false;

        // *save wallet in localstorage
        try {
          this.walletService.save(this.newWalletPaymail, wallet);
          // creating associated wallet with xpubkey on backend
          this.createWalletInMicroservice(
            this.myNewWalletName,
            this.newWalletPaymail,
            wallet
          );
          this.setQRCode();
          this.timerCountDown();
        } catch (e) {
          alert(this.i18n.tr("home.error.create_bitcoin_wallet_exists_already"));
          this.router.navigateToRoute("name_bitcoin_wallet");
        }
      }, 1000);
    } else {
      this.displayError = true;
      this.isLoading = false;
    }
  }

  /**
   * This can remain HTTP call
   * How to know ?
   * if(every devices need to know about this call)
   *  then => WebSocket
   * else
   *  HTTP Call
   * @returns
   */
  async checkIfPaymailAvailable() {
    try {
      let request = await getPublicProfile(this.newWalletPaymail);
      let is200Ok = await checkResponseStatus(request);
      let reponse = await is200Ok.json();
      if (reponse.message === "Paymail not found") {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      if (e.message === "Paymail not found") {
        return true;
      } else {
        return false;
      }
    }
  }

  async createWalletInMicroservice(
    thisWalletname: string,
    newWalletPaymail: string,
    wallet: P2PWallet
  ) {
    await init()
    let seedPhrase = this.words.join(" ");

    let privateExtendedPrivateKey = ExtendedPrivateKey.from_mnemonic(
      Buffer.from(seedPhrase, "utf8")
    );
    let xPubKey = ExtendedPublicKey.from_xpriv(privateExtendedPrivateKey)

    let bodyToSend = new WalletModel();
    (bodyToSend.userId = this.state.me._id),
      (bodyToSend.name = thisWalletname),
      (bodyToSend.xpubWif = xPubKey.to_string()),
      (bodyToSend.paymail = newWalletPaymail),
      (bodyToSend.isFavorite = false),
      (bodyToSend.xpubIndex = 0),
      this.ea.publish("wallets.add.one.backend", JSON.stringify(bodyToSend));
  }

  getWalletExPk(wallet) {
    let derived_addresses = wallet.getExtendedPublicKey();

    return derived_addresses.xpubkey;
  }

  next() {
    this.isLoading = true;
    this.router.navigateToRoute("bitcoin_account_success", {
      id: this.newWalletPaymail,
    });
  }

  copy() {
    navigator.clipboard.writeText(this.words.join(" "));
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 1000);
  }

  async sendMail() {
    this.emailHasBeenSent = true;
    try {
      this.sendMailEnabled = false;
      let request = await this.bitcoinEmail.fetch(
        "/newsletters/bitcoinWallet",
        {
          method: "POST",
          body: JSON.stringify({
            wallet: this.myNewWalletName,
            email: this.me.email,
            seed: this.words,
          }),
        }
      );
    } catch (err) {
      this.sendMailEnabled = true;
    }
  }

  generatePDF() {
    this.isPrinted = true;
    window.print();
    setTimeout(() => {
      this.isPrinted = false;
    }, 1000);
  }

  timerCountDown() {
    let timer: number = 5;

    const countdown = setInterval(() => {
      timer--;
      if (timer <= 0) {
        clearInterval(countdown);
        this.isTimerDone = true;
      }
    }, 1000);
  }

  setQRCode() {
    this.url = env.homepage + "/bitcoin/wallet/import?seed=" + this.words;
    QRCode.toCanvas(
      this.qrcode_canvas,
      this.url,
      {
        color: {
          dark: "#0000",
          light: "#dfe0e2",
        },
      },
      (error) => {
        if (error) console.log(error);
      }
    );
  }
}
