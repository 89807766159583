import { Router } from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { I18N } from "aurelia-i18n";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";
import { default as env } from "../../../../../config/environment.json";
import { UAParser} from "ua-parser-js"
import { getPublicProfile } from "services/paymailClient";



@autoinject()
export class NameBitcoinWallet {
  private name_wallet: any = "";
  private paymail_wallet: string = "";
  private paymail: string = "";
  private isLoading: boolean = false;
  private shaking: boolean = false;
  private displayError: boolean = false;
  private error: string = "";
  private sanitizedValue = "";
  private sanitizedName = "";
  private updateTrigger = "";
  private isAvailable: boolean = true;
  private paymail_env: string;
  private mybanka: string = this.devOrProd();
  private userAgent: string;

  constructor(
    private router: Router,
    private paymailWalletHttpClient: PaymailWalletClient,
    private i18n: I18N,
    
  ) {
  }
  
  async bind() {
    if (
      this.paymail_wallet != null ||
      this.paymail_wallet != undefined ||
      this.paymail_wallet != ""
    ) {
      this.paymail = this.paymail_wallet + this.mybanka;
    }
  }

  async shakeError() {
    this.shaking = true;
    setTimeout(() => {
      this.shaking = false;
    }, 200);
  }

  async validate() {
    this.isLoading = true;
    this.paymail = this.paymail_wallet + this.mybanka;
    let paymailAvailable = await this.checkIfPaymailAvailable();
    if (paymailAvailable) {
      this.router.navigateToRoute("bitcoin_account_create_key", {
        newWalletName: this.name_wallet,
        newWalletPaymail: this.paymail,
      });
    } else {
      this.displayError = true;
      this.isLoading = false;
      this.error = this.i18n.tr(
        "bitcoin.name_bitcoin_wallet.paymail_already_exist"
      );
    }
  }

  async checkIfPaymailAvailable() {
    try {
      let request = await getPublicProfile(this.paymail);
      let is200Ok = await checkResponseStatus(request);
      let reponse = await is200Ok.json();
      if (reponse.message === "Paymail not found") {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      if (e.message === "Paymail not found") {
        return true;
      } else {
        return false;
      }
    }
  }

  goBack() {
    this.router.navigateBack();
  }

  sanitizedValueChanged(newValue: string) {
    const lowercasedValue = newValue.toLowerCase();
    this.sanitizedValue = lowercasedValue.replace(/[^a-z0-9_]/g, "_");
    this.paymail_wallet = this.sanitizedValue;
  }

  sanitizeName(newValue: string) {
    const lowercasedValue = newValue;
    this.sanitizedName = lowercasedValue.replace(/[^a-zA-Z0-9_ ]/g, "_");
    this.name_wallet = this.sanitizedName;
  }

  devOrProd() {
    if (env.environment == "dev") {
      this.paymail_env = "@dev.mybanka.eu";
      return this.paymail_env
    }
    else if (env.environment == "test") {
      this.paymail_env = "@test.mybanka.eu";
      return this.paymail_env
    }
    else if (env.environment == "production") {
      this.paymail_env = "@mybanka.eu";
      return this.paymail_env
    }
  }
}
