// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/welcome/blockchain.png", import.meta.url);
// Module
var code = `<template>
  <require from="./name_bitcoin_wallet.scss"></require>
  <require from="../../../../components/loader-ring/loader-ring"></require>
  <section id="name_bitcoin_wallet" class="flex-column">
      
    <div id="header" class="flex-row header">
      <img
        id="thumbnail"
        click.delegate="router.navigateBack()"
        src="${___HTML_LOADER_IMPORT_0___}"
      />
    </div>
    <img id="thumbnail" src="${___HTML_LOADER_IMPORT_1___}">
    <h4 i18n="bitcoin.name_bitcoin_wallet.create_wallet"></h4>
    <span i18n="bitcoin.name_bitcoin_wallet.provide_info"></span>
    <form id="namewallet" class="inputs" submit.delegate="goToGenPrivateKey()">
      <div>
        <input id="name_wallet" class="top" class.bind="shaking ? 'shake' : ''" type="name" value.bind="name_wallet" minlength="3" maxlength="31" 
          i18n="[placeholder]bitcoin.name_bitcoin_wallet.your_wallet_name_placeholder" input.delegate="sanitizeName(name_wallet)"/>
      </div>
      <div class="flex-row">
        <input class="left" id="paymail_wallet" type="text" minlength="3" maxlength="64" input.delegate="sanitizedValueChanged(paymail_wallet)"
          value.bind="paymail_wallet" i18n="bitcoin.paymail" placeholder="Alice" input.trigger="bind()" maxlength="31"/>
        <input class="right" id="mybanka" type="string" value.bind="mybanka" readonly />
      </div>
    </form>
    <div class="flex-grow" if.bind="displayError"></div>
    <p class="error" if.bind="displayError">\${error}</p>
    <div class="flex-grow"></div>
    <p if.bind="!isAvailable" class.bind="isAvailable ? 'green' : 'red'">This paymail is not available.</p>
    <button class="btn btn-primary fakeDisabled" click.delegate="shakeError()"
      if.bind="!name_wallet || !paymail_wallet">
      <span t="auth.validate"></span>
    </button>
    <button class="btn btn-primary" click.delegate="validate()" form="namewallet" disabled.bind="isLoading"
      if.bind="name_wallet && paymail_wallet">
      <span t="auth.validate" if.bind="!isLoading"></span>
      <loader-ring class="loader-btn" if.bind="isLoading"></loader-ring> 
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;