// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/welcome/blockchain.png", import.meta.url);
// Module
var code = `<template>
  <require from="./add_account_bitcoin.scss"></require>
  <section id="add_account_bitcoin" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="bitcoin.add_account.add_bitcoin_account"></h1>
    </div>
    <div class="thumbnail-container">
      <img id="thumbnail" src="${___HTML_LOADER_IMPORT_1___}" alt="blockchain">
    </div>
    <div class="flex-grow" if.bind="displayError"></div>
    <h4 i18n="bitcoin.add_account.add_bitcoin_account2"></h4>
    <div class="flex-grow" if.bind="displayError"></div>
    <div class="flex-column center" click.delegate="goToSubscription()"  if.bind="displayError" style="margin: 1.5rem 0;">
      <div class="error"><span>\${errorMessage}</span></div>
      <a class=""><span t="bitcoin.add_account.button"></span></a>
    </div>
    <span class="gray" i18n="bitcoin.add_account.create_bitcoin_wallet2"></span>
    <div class="spacer-32"></div>
    <button id="bitcoin.wallet.add.button.continue" class="btn btn-primary" click.delegate="checkHowManyWallet()" t="home.continue"></button>
    <button id="bitcoin.wallet.add.button.import" class="btn btn-secondary" click.delegate="importIfSubscribe()" t="home.import"></button>
  </section>
</template>
`;
// Exports
export default code;