// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#bitcoin_account_success {
  align-items: center;
  height: 100vh;
  margin: auto;
  padding: 1rem;
  width: 100%;
}
section#bitcoin_account_success card-banka-detailled {
  aspect-ratio: 360/200 !important;
  border-radius: 30px;
  display: flex !important;
  margin: auto;
  max-height: 15rem !important;
  min-width: 80%;
  width: calc(100% - 2rem);
}
section#bitcoin_account_success card-banka-detailled section#card-banka-detailled {
  margin: 0 auto !important;
}
section#bitcoin_account_success h3,
section#bitcoin_account_success p {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/pages/bitcoin/wallet/success/bitcoin_account_success.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;AAIF;AAFE;EACE,gCAAA;EACA,mBAAA;EACA,wBAAA;EACA,YAAA;EACA,4BAAA;EACA,cAAA;EACA,wBAAA;AAIJ;AAFI;EACE,yBAAA;AAIN;AAAE;;EAEE,mDDkFyB;ECjFzB,iBAAA;EACA,gBAAA;EACA,kBAAA;AAEJ","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
