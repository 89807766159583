// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/currency/bitcoin/bsv.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/svg/components/printer_icon.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/checkbox.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/svg/components/copy_icon.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./bitcoin_account_create_key.scss"></require>
  <require from="../../../../components/loader-ring/loader-ring"></require>
  <section id="bitcoin_account_create_key" class="flex-column">
    <div id="header" class="flex-row">
      <h1  t="bitcoin.bitcoin_account_create_key.generating_key"></h1>
    </div>
    <div class="flex-grow"></div>
    <img id="thumbnail" src="${___HTML_LOADER_IMPORT_0___}" if.bind="!isLoading">
    <div class="flex-grow" if.bind="!isLoading"></div>
    <loader-ring class.bind="!isLoading ? 'fadeOut': ''" if.bind="isLoading"></loader-ring>
    <p if.bind="emailHasBeenSent" t="bitcoin.bitcoin_account_create_key.mail_sent"></p>
    <div class="words out" class.bind="!isLoading ? 'fadeIn': ''">
      <ol>
        <li repeat.for="current_word of words">\${\$index+1}. \${current_word}</li>
      </ol>
    </div>
    <p class="gray" t="bitcoin.bitcoin_account_create_key.responsability" if.bind="!isLoading"></p>
    <div class="flex-grow"></div>
    <div class="out" class.bind="!isLoading ? 'fadeIn': ''"></div>
      <div class="flex-grow" if.bind="displayError"></div>
      <p class="error" if.bind="displayError">\${errorMessage}</p>
      <div class="flex-grow" if.bind="displayError"></div>

      <div class="buttons flex-column" if.bind="!isLoading">
        <div class="flex-row">
          <div class="send_pdf" if.bind="!isLoading && !displayError">
            <button id="bitcoin.wallet.create.button.pdf" class="btn btn-secondary" click.delegate="generatePDF()">
              <img src="${___HTML_LOADER_IMPORT_1___}" if.bind="!isPrinted">
              <img src="${___HTML_LOADER_IMPORT_2___}" if.bind="isPrinted"/>
            </button>
          </div>
          <div class="flex-grow"></div>
          <button id="bitcoin.wallet.create.button.copy"  class="actions btn btn-secondary" if.bind="copyEnabled" click.delegate="copy()">
            <img src="${___HTML_LOADER_IMPORT_3___}" if.bind="!isCopied">
            <img src="${___HTML_LOADER_IMPORT_2___}" if.bind="isCopied"/>
          </button>
        </div>
        <!-- EXPORT PRIVATE KEY VIA .JSON (as 1satordinals.com) COMING SOON  -->
        <div class="flex-grow"></div>
        <button id="bitcoin.wallet.create.button.next"  class="btn btn-primary" i18n="home.noted" disabled.bind="!isTimerDone" click.delegate="next()"> <!-- -->
          \${timer}
          <loader-ring if.bind="isLoading"></loader-ring>
        </button>
      </div>
    </div>
    <canvas id="qrcode_canvqas" ref="qrcode_canvas"></canvas>
  </section>
</template>
`;
// Exports
export default code;