// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/currency/bitcoin/bsv.png", import.meta.url);
// Module
var code = `<template>
  <require from="./bitcoin_import.scss"></require>
  <require from="../../../../components/loader-ring/loader-ring"></require>
  <section id="bitcoin_import" class="flex-column">
    <div id="header" class="flex-row">
      <h1>Bitcoin Import</h1>
    </div>    
    <div class="flex-grow"></div>
    <img id="thumbnail" src="${___HTML_LOADER_IMPORT_0___}">
    <div class="flex-grow"></div>
    <form class="words inputs" id="seedForm" if.bind="isWordShown">
      <div class="words">
        <input id="bitcoin.wallet.import.input.0" value.bind="words[0]" placeholder="Word 1" paste.delegate="handlePaste(\$event, 0)">
      </div>
      <div class="words">
        <input  id="bitcoin.wallet.import.input.1" value.bind="words[1]"  placeholder="Word 2">
      </div>
      <div class="words">
        <input  id="bitcoin.wallet.import.input.2" value.bind="words[2]" placeholder="Word 3">
      </div>
      <div class="words">
        <input  id="bitcoin.wallet.import.input.3" value.bind="words[3]" placeholder="Word 4">
      </div>
      <div class="words">
        <input  id="bitcoin.wallet.import.input.4" value.bind="words[4]" placeholder="Word 5">
      </div>
      <div class="words">
        <input  id="bitcoin.wallet.import.input.5" value.bind="words[5]" placeholder="Word 6">
      </div>
      <div class="words">
        <input  id="bitcoin.wallet.import.input.6" value.bind="words[6]" placeholder="Word 7">
      </div>
      <div class="words">
        <input  id="bitcoin.wallet.import.input.7" value.bind="words[7]" placeholder="Word 8">
      </div>
      <div class="words"> 
        <input  id="bitcoin.wallet.import.input.8" value.bind="words[8]" placeholder="Word 9">
      </div>
      <div class="words">
        <input  id="bitcoin.wallet.import.input.9" value.bind="words[9]" placeholder="Word 10">
      </div>
      <div class="words">
        <input  id="bitcoin.wallet.import.input.10" value.bind="words[10]" placeholder="Word 11">
      </div>
      <div class="words">
        <input  id="bitcoin.wallet.import.input.11" value.bind="words[11]" placeholder="Word 12">
      </div>
    </form>
    <div class="flex-grow"></div>
    <div class="error" class.bind="displayError ? 'shake' : ''">
      <span class="error">\${errorMessage}</span>
    </div>
    <div class="flex-grow"></div>
    <button  id="bitcoin.wallet.import.button.submit" class="btn btn-primary formobile" type="submit" disabled.bind="isLoading" form="seedform" click.delegate="importWallet()">
      <span if.bind="!isLoading" t="bitcoin.wallet.import.btn"></span>
      <loader-ring if.bind="isLoading"></loader-ring>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;