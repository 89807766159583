import { autoinject } from "aurelia-dependency-injection";
import { Router } from "aurelia-router";
import { I18N } from "aurelia-i18n";
import { connectTo } from "aurelia-store";
import { State } from "state";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";

@connectTo()
@autoinject
export class AddAccountBitCoin {
  private displayError = false;
  private errorMessage: string;
  private state: State;

  constructor(
    private router: Router,
    private i18n: I18N,
  ) {}

  goBack() {
    this.router.navigateBack();
  }
  goToSubscription() {
    this.router.navigateToRoute("subscription");
  }
  importIfSubscribe() {
    this.router.navigateToRoute("bitcoin_import");
  }

  checkHowManyWallet() {
    let me = this.state.me;
    let blockChainWallets: IGenericAccount[] = [];
    this.state.wallets.forEach((wallet: IGenericAccount) => {
      if (wallet.isBlockchain) {
        blockChainWallets.push(wallet);
      }
    });
    if (this.state.me.isSubscribed === true) {
      this.router.navigateToRoute("name_bitcoin_wallet");
      return;
    }
    if (blockChainWallets.length > 0 && me.isSubscribed === false)
      {
      this.errorMessage = this.i18n.tr("bitcoin.add_account.need_subscription");
      this.displayError = true;
    } else {
      this.router.navigateToRoute("name_bitcoin_wallet");
    }
  }
}
