// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/xmark-large.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/currency/bitcoin/bsv.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/img/banka_logo.png", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/img/currency/bitcoin/bsvfull.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/img/arrow-right_blue.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./transactions_details_bitcoin.scss"></require>
  <require from="../../../components/max-length"></require>
  <require from="../../../components/number-format"></require>
  <require from="../../../components/ValueConverters/currency-denomination"></require>  <require from="../../../components/date-format-short"></require>
  <require from="../../../components/date-format-full"></require>
  <require from="../../../components/max-length"></require>
  <require from="../../../components/ValueConverters/iban-converter"></require>
  <section id="transactions_details_bitcoin" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="dialogController.ok()" src="${___HTML_LOADER_IMPORT_0___}">
      <!-- <h1 i18n="bitcoin.transactions_details_bitcoin.title">Details</h1> -->
    </div>
    <!-- <div class="spacer-32"></div> -->
    <div class="facture flex-column">
      <img src="${___HTML_LOADER_IMPORT_1___}" if.bind="!receiver.avatar" />
      <div class="amount flex-column">
        <h2 class="bitcoin_amount \${(tx_bsv.output_amount[0] < 0) ? 'negative' : 'positive'} \${(blurred) ? 'blurr': ''}">₿ \${tx_bsv.output_amount[0] >= 0 ? '+' : ''}\${(tx_bsv.output_amount[0] / 100000000) | numberFormat:'0.00000000'}
        </h2>
        <p class="date">\${tx_bsv.createdAt | dateFormat}</p>
        <!-- <button class="btn btn-primary"></button> -->
      </div>
      <div class="notes flex-row">
        <p>\${tx_bsv.comment}</p>
      </div>
      <div class="sender_receiver flex-column">
        <div class="sender_receiver_container flex-column">
          <div class="from_sender flex-column">
            <h2 i18n="from"></h2>
            <div class="sender flex-row">
              <img src="${___HTML_LOADER_IMPORT_2___}" alt="">
              <!-- <img src.bind="receiver.avatar" if.bind="receiver.avatar"/> -->
                <div class="sender_with_photo flex-column">
                 <p id="sender_name"if.bind="!receiver && tx_bsv.output_amount[0] > 0">\${tx_bsv.senderPaymail}</p>
                  <p id="paymail">\${tx_bsv.senderPaymail}</p>
                </div>
                <!-- <div class="add_contact"><img src="/static/img/bxs-user-plus.svg" alt="">
                </div> -->
            </div>
          </div>
          <div class="to_receiver flex-column">
            <h2 i18n="to"></h2>
            <div class="receiver flex-row">
              <img src="${___HTML_LOADER_IMPORT_3___}" alt="">
              <!-- <img src.bind="receiver.avatar" if.bind="receiver.avatar"/> -->
              <div class="reciver_with_photo flex-column">
                <p  id="receiver_name"if.bind="tx_bsv.output_amount[0] > 0">\${me.displayName}</p>
                <p  id="receiver_name" if.bind="tx_bsv.output_amount[0] > 0">\${wallet.paymail}</p>
                <p  id="paymail" if.bind="tx_bsv.output_amount[0] < 0 && receiver">\${receiver.name}</p>
                <p id="paymail" if.bind="tx_bsv.output_amount[0] < 0 && receiver">\${tx_bsv.senderPaymail}</p>
              </div>
              <p if.bind="!receiver && tx_bsv.output_amount[0] < 0"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="details flex-column">
        <div class="transaction_id flex-column">
          <p class="blue" i18n="bitcoin.transactions_details_bitcoin.title">Transaction ID</p>
          <div class="content flex-row">
          <!-- <p>\${tx_bsv.status}</p> -->
          <a href="https://whatsonchain.com/tx/\${tx_bsv.txid}">
            \${tx_bsv.txid.slice(0,22)}
          </a>
          <!-- <img src="/static/img/copy_icon_blue.svg" alt=""> -->
        </div>
        </div>
        <a href="https://whatsonchain.com/tx/\${tx_bsv.txid}">
          <div class="check_on_blockchain flex-row">
            <p>Check on Blockchain</p>
            <div class="arrow_icon"><img src="${___HTML_LOADER_IMPORT_4___}" alt=""></div>
          </div>
        </a>
      </div>
      <!-- <div class="footer flex-row">
        <div class="share flex-column">
          <a href=""><img src="/static/img/share_icon_blue.svg" alt=""></a>
          <p>Share Receipt</p>
        </div>
      </div> -->
    </div>
    <div class="flex-grow"></div>
  </section>
</template>
`;
// Exports
export default code;