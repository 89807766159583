import { Router } from "aurelia-router";
import { UsersHttpClients } from "../../../../../http_clients/UsersHttpClients";
import { AuthService } from "aurelia-auth";
import { autoinject } from "aurelia-framework";
@autoinject
export class bitcoinImportConfirmed {
  constructor(
    private authService: AuthService,
    private usersHttpClients: UsersHttpClients,
    private router: Router,
  ) {}

  bind(){
    setTimeout(() => this.router.navigateToRoute("choose_wallet"), 3000)
  }
  
  goToHome() {
    this.router.navigateToRoute("home");
  }
  goToImport() {
    this.router.navigateToRoute("bitcoin_import");
  }
  goToChooseWallet() {
    this.router.navigateToRoute("choose_wallet");
  }
}
