// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("../../../../../../static/svg/figma/refresh-cw.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./confirmed.scss"></require>
  <require from="../../../../../components/animated_checkmark/animated_checkmark"></require>
  <section id="bitcoin_import_confirmed" class="flex-column">
    <div class="flex-grow"></div>
    <div class="click1" click.delegate="goToChooseWallet()">
      <animated-checkmark></animated-checkmark>
    </div>
    <div style="height: 5rem;"></div>
    <div style="height: 2rem;"></div>
    <div class="click2" click.delegate="goToImport()" if.bind="(me.isSubscribed == true)">
      <img class="reload" src="${___HTML_LOADER_IMPORT_0___}">
    </div>
    <div class="flex-grow"></div>
  </section>
</template>
`;
// Exports
export default code;