import { autoinject, computedFrom, inject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { SingletonService } from "singleton";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { User } from "components/models/UserModel";
import { WalletInfo, WalletsManager } from "services/WalletManager.service";
import { P2PWallet } from "bsv-wallet";
import { I18N } from "aurelia-i18n";
import { NewsletterHttpClients } from "http_clients/NewsletterHttpClients";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";
import init, {
  ExtendedPrivateKey,
  ExtendedPublicKey,
} from "bsv-wasm/bsv_wasm";
import { WalletService } from "services/wallets.service";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
import { EventAggregator } from "aurelia-event-aggregator";
@connectTo()
@autoinject()
export class bitcoinImport {
  private seedPhrase: string = "";
  private privateKey: string;
  private words: string[] = ["", "", "", "", "", "", "", "", "", "", "", ""];
  private isLoading: boolean = false;
  private name: string;
  private isCopied: boolean = false;
  private copyEnabled: boolean = true;
  private sendMailEnabled: boolean = true;
  private myNewWalletName;
  private newWalletPaymail;
  private send_email: boolean = true;
  private me: User;
  private actionTaken = 0;
  private emailHasBeenSent = false;
  private isTimerDone = false;
  private allCryptoWallets: Array<WalletInfo>;
  private displayError = false;
  private errorMessage: string;
  private paste: ClipboardEvent;
  private isWordShown: boolean = true;

  accountIdentification: string; // Declare the selected paymail value from /choose_Wallet

  constructor(
    private walletService: WalletService,
    private singleton: SingletonService,
    private router: Router,
    private i18n: I18N,
    private bitcoinEmail: NewsletterHttpClients,
    private paymailWalletHttpClient: PaymailWalletClient,
    private store: Store<State>,
    private ea: EventAggregator,
    private state: State
  ) {}


  async handlePaste(event: ClipboardEvent) {
    this.isWordShown = false;
    const pastedText = event.clipboardData?.getData("text/plain");
    const lowerCasePastedText = pastedText.toLowerCase().trim();
    const splitWords = await lowerCasePastedText?.split(/\s+/);

    for (let i = 0; i < 12; i++) {
        this.words[i] = splitWords?.[i] || "";
    }

    this.isWordShown = true;
  }

  @computedFrom("words")
  get wordInputs() {
    return this.words.map((word, index) => ({
      index,
      value: word,
      placeholder: `Word ${index + 1}`,
    }));
  }

  async importWallet() {
    try {

      this.displayError = false;
      this.isLoading = true;
      this.seedPhrase = this.words.join(" ");
      await init();
      const wallet = new P2PWallet({ key: this.seedPhrase } as any);
      let privateExtendedPrivateKey = ExtendedPrivateKey.from_mnemonic(
        Buffer.from(this.seedPhrase, "utf8")
      );
        let xpubWif = ExtendedPublicKey.from_xpriv(privateExtendedPrivateKey)
        let bankaWallet = await this.restoreWallet(xpubWif.to_string());
        this.walletService.save(bankaWallet.wallet.paymail, wallet);
        if (bankaWallet) {
          this.isLoading = false;
          this.updateWalletState(bankaWallet.wallet);
          this.router.navigateToRoute("bitcoin_import_confirmed");
        }
    } catch(e) {
      console.log("Import error: " + e)
      this.displayError = true;
      this.errorMessage = this.i18n.tr("error.import.wrong_seed")
    }
  }

  async restoreWallet(xpubWif){
    let request = await this.paymailWalletHttpClient.fetch(`/wallet/restore/${xpubWif}`, {
      method: "PATCH"
    });
    let is200OK = await checkResponseStatus(request);
    let restoredWallet = await is200OK.json();
    return restoredWallet;
  }

  async updateWalletState(wallet) {
    wallet.id = wallet._id;
    wallet.isBlockchain = true; // Assuming it's a blockchain wallet
    wallet.balance = wallet.balanceSatoshis || 0;
    wallet.identification = wallet.paymail;
    wallet.currency = "BSV"; 
    (wallet.picture = "/img/currency/bitcoin/bitcoin_account_white.png"), // Assuming no picture
      await this.store.dispatch("wallets.add.one.state", wallet);
    this.ea.publish("app.started");
  }

  handleErrors(error) {
    this.displayError = true;
    this.isLoading = false;
    this.errorMessage = this.i18n.tr("home.error.error_global");
  }

  async getWalletFromExtended(extended_pk) {
    let request = await this.paymailWalletHttpClient.fetch(
      `/wallet/deleted/xpubwif/${extended_pk}`
    );
    let is200OK = await checkResponseStatus(request);
    let reponse = await is200OK.json();
    return reponse;
  }

  getWalletExPk(wallet) {
    let derived_addresses = wallet.getExtendedPublicKey();
    return derived_addresses.xpubkey;
  }

  async patchWalletIfFound(
    thisWalletname,
    newWalletPaymail,
    wallet,
    id
  ) {
    let request = await this.paymailWalletHttpClient.fetch("/wallet/" + id, {
      method: "PATCH",
      body: JSON.stringify({
        name: thisWalletname,
        paymail: newWalletPaymail,
        isDeleted: false,
      }),
    });
    let is200Ok = await checkResponseStatus(request);
    let response = await is200Ok.json();
    return response;
  }

  async sendMail() {
    this.emailHasBeenSent = true;
    try {
      this.sendMailEnabled = false;
      let request = await this.bitcoinEmail.fetch(
        "/newsletters/bitcoinWallet",
        {
          method: "POST",
          body: JSON.stringify({
            wallet: this.myNewWalletName,
            email: this.me.email,
            seed: this.words,
          }),
        }
      );
    } catch (err) {
      this.sendMailEnabled = true;
    }
  }

  async checkIfPaymailAvailable() {
    let request = await this.paymailWalletHttpClient.fetch(
      "/bsvalias/pki/" + this.newWalletPaymail
    );
    let is200Ok = await checkResponseStatus(request);
    let reponse = await is200Ok.json();
    if (reponse === "Paymail is available") {
      return true;
    } else {
      return false;
    }
  }
}

