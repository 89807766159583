import { autoinject } from "aurelia-dependency-injection";
import { Router } from "aurelia-router";
import {Store, connectTo } from "aurelia-store";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { SingletonService } from "singleton";
import { State } from "state";
@connectTo() 
@autoinject()
export class BitcoinAccountSuccess {
  private wallet: any;
  private justCreatedWalletPaymail;
  private state: State;
  private allCryptoWallets: IGenericAccount[];

  constructor(
    private router: Router,
    private store: Store<State>
  ) {}

  async activate(params, { id }) {
    this.justCreatedWalletPaymail = params.id;    
  }

  bind() {
    this.wallet = this.state.wallets.find((wallet) => { 
      return wallet.identification === this.justCreatedWalletPaymail 
    })
    const walletIndex = this.state.wallets.findIndex((wallet) => { 
      return wallet.identification === this.justCreatedWalletPaymail
    })

    this.store.dispatch('account.selected.update', walletIndex);
    setTimeout(() => this.router.navigateToRoute("home"), 3000)
  }

  next() {
    this.router.navigateToRoute("home");
  }
}
